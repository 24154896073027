export default {
	name: 'Disclaimer_dualcurrencies',
	created () {
		this.$nextTick(function () {

		})
	},
	mounted () {

	},
	props: [],
	data () {
		return {

		}
	},
	computed: {

	},
	watch: {

	},
	methods: {

	}
}
